.headingNotification {
  color: #132649;
  font-size: 22px;
  font-family: Circular Std Bold;
}
.paragraph-notificaton {
  color: #5c6064;
  font-size: 14px;
  font-family: Circular Std Book;
}
