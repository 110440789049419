/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

/* .message-header {
    background-color: #e9f8f6;
    border-radius: 1px;
    display: flex;
    justify-content: space-between;
    align-items: "center";

} */

.chat-box-header {
  background-color: #e9f8f6;
  border-radius: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 105px;
}

.chat-box-inner-header {
  display: flex;
  justify-content: space-between;
  gap: 35px;
}

/* .letf-group {
    flex: 1;
} */

/* .ight-group {
    padding-top: 20px;
} */

/* .msg-header {
    border: 1px solid #ccc;
    width: 100%;
    height: 15%;
    border-bottom: none;
    display: inline-block;
    background-color: "red";
} */

.msgimg {
  width: 75px;
}

/* .container1 {
    width: 270px;
    height: auto;
    float: left;
    margin: 10px;
} */

/* .active {


    margin-top: 20px;
    padding-left: 140px;
    line-height: 1;
} */

/* .chat-page {
    padding: 0 0 80px 0;
    
} */

/* .msg-inbox {
    border: 1px solid #ccc;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
} */

.chats {
  padding: 30px 0px 0 0px;
}

.msg-page {
  height: 68vh;
  overflow-y: auto;
}

input:focus {
  outline: none;
  border: none !important;
  box-shadow: none !important;
}

.send-icon {
  font-weight: bold !important;
}

received-chats-img {
  /* display: inline-block;
            width: 500px;
            float: left; */
  display: inline-block;
  width: 50px;
  float: left;
  margin-right: 1rem;
}

.received-chats {
  display: flex;
  gap: 0px;
  /* width: 50%; */
}

.received-msg-inbox {
  width: 61%;
}

.received-msg-inbox p {
  background: #efefef none repeat scroll 0 0;
  /* border-radius: 10px; */
  border-bottom-left-radius: 25px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #ffffff;
  font-size: 18px;
  font-family: 'Circular Std Medium';
  font-weight: 400;
  margin-left: 1rem;
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;
  background-color: #6cd2c2;
}

.send-chats {
  display: flex;
  gap: 0px;
}

.send-chats-img {
  /* display: inline-block;
            width: 500px;
            float: left; */
  display: inline-block;
  width: 50px;
  float: right;
  margin-right: 1rem;
}

.send-msg-inbox p {
  background: #efefef none repeat scroll 0 0;
  border-radius: 10px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  margin-left: 1rem;
  padding: 1rem;
  width: 100%;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 5px 2px;
  background-color: #6cd2c2;
}

/* p {
    overflow-wrap: break-word;
} */

.msg-time {
  color: #777;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
  font-family: 'Circular Std';
  margin-left: 20px;
}

.out-going-msg-time {
  color: #777;

  font-size: 12px;
  margin: 8px 0 0;
  font-family: 'Circular Std';
}

/* ============================================= */

.outgoing-chats {
  overflow: hidden;
  margin: 26px 20px;
  float: right;
  /* display: flex;
    gap : 0px */
}

.outgoing-chats-msg p {
  color: #fff;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  font-size: 14px;
  color: #132649;
  font-size: 18px;
  font-family: 'Circular Std Medium';
  font-weight: 400;
  padding: 5px 10px 5px 12px;
  /* width: 50%; */
  padding: 1rem;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 5px 2px;
  float: right;
}

.outgoing-chats-msg {
  float: right;
  width: 61%;
}

.outgoing-chats-img {
  display: inline-block;
  width: 50px;
  float: right;
  margin-right: 1rem;
}

/* @media only screen and (max-device-width: 850px) {

    *,
    .time {
        font-size: 28px;
    }

    img {
        width: 65px;
    }



    .msg-page {
        max-height: none;
    }


    .outgoing-chats-msg p {
        font-size: 28px;
    }
}

@media only screen and (max-device-width: 450px) {

    *,
    .time {
        font-size: 28px;
    }

    img {
        width: 65px;
    }



    .msg-page {
        max-height: none;
    }



    .outgoing-chats-msg p {
        font-size: 28px;
    }
} */

.chatlist-main-div {
  background-color: #ffffff;
  border-radius: 1px;
  padding: 5px;
}

.chatlist-div {
  background-color: #ffffff;
  border-radius: 1px;
  display: flex;
  justify-content: space-between;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 5px;
  height: 95px;
}

.chatlist-userimage {
  margin-left: 30px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: fit-content;
}

.number-of-messages {
  width: 28px;
  border-radius: 50%;
  text-align: center;
  background-color: #6cd2c2;
  color: white;
  font-size: 15px;
}

.chat {
  color: 'gray';
  font-family: 'Jeff Levine';
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.send-message-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f1ae01;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 33px;
}

.msg-bottom {
  border-top: 1px solid #ccc;
  position: relative;
  height: 11%;
  background-color: rgb(239 239 239);
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.noDataFound {
  /* background-color: #f9f9f9; */
  align-items: center;
  display: flex;
  justify-content: center;
}

.no-data-found-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20%;
  padding-bottom: 20%;
}

.left-side-view {
  float: left;
  overflow-y: scroll;
  /* height: 90vh; */
}

.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: scroll;
}

.right-side-chat {
  color: black;
  border: 3px solid #e7e9ed;
  margin-top: 0.6rem !important;
  font-family: 'Circular Std Medium';
  font-size: 20px;
  width: 80%;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
  padding: 15px !important;
}

.left-side-chat {
  background: #6cd2c2;
  color: white;
  margin-top: 0.6rem !important;
  font-family: 'Circular Std Medium';
  font-size: 20px;
  width: 80%;
  border-bottom-left-radius: 25px !important;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  padding: 15px !important;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}