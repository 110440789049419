.CarBlogHeading {
  font-family: Circular Std Bold;
}
.blog-paragraph {
  font-family: Circular Std Book;
  font-size: 18px;
  color: #8992a4;
}
.blog-date {
  font-family: Circular Std Book;
  color: #8992a4;
}
.blog-main-heading {
  font-family: Circular Std Bold;
  color: #132649;
}
.smallText {
  font-family: Circular Std Book;
  font-size: 15px;
  color: #f1ae01;
}
