.Queries {
  font-family: Circular Std Book;
  color: grey;
}
/* Override Bootstrap accordion colors */
.accordion-button {
  background-color: transparent !important;
  border-color: white !important;
  color: #132649 !important;
}
/* Change border color of accordion when selected */
.accordion-button:focus,
.accordion-button.focus {
  border-color: white !important; /* Change 'your_color' to your desired border color */
}
/* Change border color of accordion item when selected */
.accordion-item:focus-within,
.accordion-item.focus-within {
  border-color: white !important; /* Change 'your_color' to your desired border color */
}
/* Remove focus border color from accordion button */
.accordion-button:focus,
.accordion-button.focus {
  outline: none;
}
/* Remove focus and active border color from accordion button */
.accordion-button:focus,
.accordion-button.focus,
.accordion-button:active,
.accordion-button.active {
  outline: white;
  /* Add any other styles for the button when focused or active */
}
/* Change background color of accordion button when focused or active */
.accordion-button:focus,
.accordion-button.focus,
.accordion-button:active,
.accordion-button.active {
  background-color: white;
  color: #132649; /* Add any other styles for the button when focused or active */
}
.subparagraphAccordian {
  font-family: Circular Std Book;
  font-size: 14px;
  color: #132649;
}
.HeadingHelp {
  font-family: Circular Std Bold;
  color: #00071a;
  font-weight: 700;
  font-size: 50px;
  padding-top: 6rem;
  background-color: aquamarine;
}
.formHeading {
  font-family: Circular Std Medium;
  color: '#00071A';
  font-size: 35px;
}
/* .accordion-header.active {
  outline: white !important;
}
.accordion-button:not(.collapsed) {
  border: transparent !important; */

.accordion-header {
  border: none !important;
}
