body {
  font-family: 'Roboto', sans-serif;
  background-color: FFFFFF;
}

.content {
  padding: 7rem 0;
}

h2 {
  font-size: 20px;
}

.half,
.half .container > .row {
  max-height: auto;
  min-height: 100vh;
}

@media (max-width: 991.98px) {
  .half .bg {
    height: 200px;
  }
}

.half .contents {
  background: #f6f7fc;
}

.half .contents,
.half .bg {
  width: 50%;
}
@media (max-width: 1199.98px) {
  .half .contents,
  .half .bg {
    width: 100%;
  }
}
.form-control::placeholder {
  color: #132649;
  opacity: 1;
}
.half .contents .form-control,
.half .bg .form-control {
  border: none;
  -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 54px;
  background: #fff;
}
.half .contents .form-control:active,
.half .contents .form-control:focus,
.half .bg .form-control:active,
.half .bg .form-control:focus {
  outline: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}

.half .bg {
  background-size: cover;
  background-position: center;
}

.authHeading {
  font-size: 37px;
  color: #132649;
  font-family: 'Circular Std Black';
}
