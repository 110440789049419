.btn-custom {
  background-color: white;
  color: black;
}

.first-div {
  background-color: #ffffff;
  padding: 35px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 18px;
}

.second-div {
  display: flex;
  /* gap: 20%; */
  justify-content: space-between;
  width: '450px';
}

.client-image-div {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.client-image {
  max-width: 70px;
  /* height: 70px; */
  border-radius: 50%;
}

.client-name {
  font-family: 'Circular Std';
  color: #132649;
}

.car-name-image {
  width: 100%;
  border-radius: 15px;
  background-color: #f6f6f8;
  margin-top: 20px;
  display: flex;
  padding: 12px;
  /* padding-top: 6px; */
  gap: 15px;
  font-size: 20px;
}

.car-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.req-details {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.req-details-left {
  color: #90949b;
  font-family: 'Circular Std Book';
  font-size: 22px;
}

.req-details-right {
  color: '#132649';
  font-family: 'Circular Std Medium';
  font-size: 22px;
}

.btns {
  display: flex;
  justify-content: space-around;
  /* gap: 10px; */
  margin-top: 10px;
}

.button-3 {
  appearance: none;
  background-color: #44de94;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #44de94;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-3:active {
  background-color: #44de94;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

.button-4 {
  appearance: none;
  background-color: #ff6d6d;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-4:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-4:hover {
  background-color: #ff6d6d;
}

.button-4:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-4:disabled {
  background-color: #ff6d6d;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-4:active {
  background-color: #ff6d6d;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}