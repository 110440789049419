.card {
  margin-top: 35px;
}

body {
  margin-top: 20px;
  background: #f7f8fa;
}

.avatar-xxl {
  height: 7rem;
  width: 7rem;
}

.card {
  margin-bottom: 20px;
  -webkit-box-shadow: 0 2px 3px #eaedf2;
  box-shadow: 0 2px 3px #eaedf2;
  width: 100%;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #038edc;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.bg-soft-primary {
  background-color: rgba(3, 142, 220, 0.15) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: #038edc;
}

.nav-tabs-custom .nav-item .nav-link {
  border: none;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: #038edc;
}

.avatar-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 12px;
}

.border-end {
  border-right: 1px solid #eff0f2 !important;
}

.d-inline-block {
  display: inline-block !important;
}

.badge-soft-danger {
  color: #f34e4e;
  background-color: #f34e4e;
}

.badge-soft-warning {
  color: #d8af3e;
  background-color: #d8af3e;
}

.badge-soft-success {
  color: #51d28c;
  background-color: #51d28c;
}

.avatar-group .avatar-group-item {
  margin-left: -14px;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.avatar-sm {
  height: 3rem;
  width: 3rem;
}

.nav-tabs-custom .nav-item {
  position: relative;
  color: #343a40;
}

.nav-tabs-custom .nav-item .nav-link.active:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.nav-tabs-custom .nav-item .nav-link::after {
  content: '';
  background: #038edc;
  height: 2px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -2px;
  -webkit-transition: all 250ms ease 0s;
  transition: all 250ms ease 0s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.badge-soft-secondary {
  color: #74788d;
  background-color: rgba(116, 120, 141, 0.1);
}

.badge-soft-secondary {
  color: #74788d;
}

.work-activity {
  position: relative;
  color: #74788d;
  padding-left: 5.5rem;
}

.work-activity::before {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  left: 66px;
  border-left: 1px solid rgba(3, 142, 220, 0.25);
}

.work-activity .work-item {
  position: relative;
  border-bottom: 2px dashed #eff0f2;
  margin-bottom: 14px;
}

.work-activity .work-item:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.work-activity .work-item::after,
.work-activity .work-item::before {
  position: absolute;
  display: block;
}

.work-activity .work-item::before {
  content: attr(data-date);
  left: -157px;
  top: -3px;
  text-align: right;
  font-weight: 500;
  color: #74788d;
  font-size: 12px;
  min-width: 120px;
}

.work-activity .work-item::after {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -26px;
  top: 3px;
  background-color: #fff;
  border: 2px solid #038edc;
}

.input-group rounded mb-3 w-25 {
  float: right;
  margin-bottom: 20px;
}

.text {
  font-family: fantasy;
  float: left;
  margin-top: 0%;
}

input.search {
  width: 260px;
  border: 1px solid #555;
  display: block;
  padding: 9px 4px 9px 40px;
}

.form-control:focus {
  box-shadow: none;
}

.form-control-underlined {
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  padding-left: 0;
}

body {
  background: #d1d5db;
}

.height {
  height: 100vh;
}

.form {
  position: relative;
  display: flex;
}

.form .fa-search {
  position: relative;
  top: 20px;
  left: 20px;
  color: #9ca3af;
}

/* .form span {
  position: absolute;
  right: 17px;
  top: 13px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
} */

.left-pan {
  padding-left: 7px;
}

.left-pan i {
  padding-left: 10px;
}

.form-input {
  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
}

.form-input:focus {
  box-shadow: none;
  border: none;
}

.col text-muted fs-2 {
  padding-left: 10px;
}

.card-container {
  margin-bottom: 10px;
}

/* .custom-Height {
  height: 50px; /* Adjust the width as needed */
/* } */
.block {
  height: 150px;
}

.button {
  display: inline-block;
  padding: 15px 25px;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #ffbb51;
  background-color: #132649;
  border: none;
  border-radius: 15px;
  /* box-shadow: 0 9px #999; */
}

/* .button:hover {
  background-color: #3e8e41;
} */

.button:active {
  background-color: #050d82;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}

.input-radius-2 {
  border-radius: 2px;
}

.paragraph {
  font-family: 'Circular Std Book Italic';
  color: '#5c6064';
}

.rounded-circle avatar-sm-1 {
  margin-left: 20px;
  height: 1rem;
  width: 1rem;
}

.form-search form-inline ::placeholder {
  color: '#8892a3;';
}

.rounded-circle avatar-sm-1 {
  padding-left: 50px;
  margin-bottom: -4rem;
}

#textarea {
  width: 400px;
  height: 100px;
  border: none;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

#textarea div {
  width: 70px;
  height: 70px;
}

.textarea {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 20px;
  /* Adjust width and height as needed */
  height: 20px;
  border-radius: 50%;
  /* Makes the div rounded */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.textarea-container {
  width: 800px;
}

.card-List-Left {
  font-family: 'Circular Std Book';
  color: #8892a3;
}

.card-List-Right {
  font-family: 'Circular Std Medium';
  color: #132649;
}

.button1 {
  display: inline-block;
  padding: 15px 25px;
  font-size: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  border: none;
  border-radius: 15px;
}

.form-check-label {
  margin-bottom: 70px;
}

html {
  background: #eff0f2;
}

.constrain {
  max-width: 300px;
  padding: 60px;
  margin: 0 auto;
}

input {
  margin-bottom: 40px;
}

.input-elevated {
  font-size: 16px;
  line-height: 1.5;
  border: none;
  background: #ffffff;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 20px 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  width: 300px;
  padding: 0.5em 1em 0.5em 2.5em;
}

.input-elevated::placeholder {
  color: #838d99;
}

.input-elevated:focus {
  outline: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
}