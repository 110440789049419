/* :where(.css-dev-only-do-not-override-1r287do).ant-picker .ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-inline-start: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
  transition:
    opacity 0.2s,
    color 0.2s;
  display: none;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  width: 100%;
  font-size: 14px;
  line-height: 1;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  position: relative;
  display: inline-flex;
  align-items: center;
  border-radius: 6px;
  transition:
    border 0.2s,
    box-shadow 0.2s,
    background 0.2s;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker .ant-picker-clear {
  position: absolute;
  top: 50%;
  inset-inline-end: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  display: none;
  transition:
    opacity 0.2s,
    color 0.2s;
}

:where(.css-dev-only-do-not-override-1r287do).ant-picker-outlined {
  background: #edeaea;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border: none;
  background: white;
  color: #000;
}

input {
  background: #fff !important;
} */

.time-picker input {
  background: #fff !important;
}

.ant-picker-outlined {
  border: none !important;
}

.ant-select-selection-placeholder {
  color: #000 !important;
}


.ant-picker-input input::placeholder {
  color: #858E9F !important;
}