.HeadingAll {
  font-family: 'Circular Std Black';
  color: #132649;
}

.car-detail-featureDetailItalic {
  font-family: 'Circular Std Book Italic';
  font-size: 16px;
  color: grey;
}

.car-detail-featureName {
  color: #132649;
  font-family: 'Circular Std Book';
  font-size: 18px;
}

.car-detail-featureDetail {
  color: #132649;
  font-family: 'Circular Std Medium';
  font-weight: 500;
  font-size: 20px;
  text-align: right;
}

.First-yellow-division {
  width: 100% !important;
  border-radius: 12px !important;
  background-color: #f1ae01 !important;
  color: white !important;
  position: relative;
  border: none;
}

.main-text {
  font-family: Circular Std Bold;
  font-size: 25px;
}

.main-text-1 {
  font-family: Circular Std Bold;
  font-size: 15px;
}

.sub-text-1 {
  font-family: 'Circular Std Book';
  font-size: 14px;
  color: white;
  margin-top: -0.5rem;
}

.second-division {
  width: 100% !important;
  border-radius: 12px !important;
  color: #132649 !important;
}

.second-main-text {
  font-family: Circular Std Bold;
  font-size: 25px;
}

.second-sub-text {
  font-family: 'Circular Std Book';
  font-size: 14px;
  color: #a1a8b6;
  margin-top: -0.5rem;
}

@media (max-width: 768px) {
  .d-flex.justify-content-start.gap-3 {
    flex-wrap: wrap;
    /* Allow items to wrap to the next line */
    justify-content: center;
    /* Center items horizontally */
  }

  .First-yellow-division,
  .second-division {
    width: 80%;
    /* Make each div take up full width on mobile */
    margin-bottom: 10px;
    /* Add some space between each div */
  }

  .main-text,
  .main-text-1,
  .second-sub-text {
    font-size: 14px;
    /* Adjust font size for better readability on smaller screens */
  }
}

.Left-list {
  font-family: 'Circular Std Book';
  color: #132649;
}

.Right-list {
  font-family: 'Circular Std Medium';
  color: #132649;
}

.small {
  font-family: 'Circular Std Book';
  color: #a1a8b6;
}

.plusSign {
  font-family: 'Circular Std Medium';
  color: #a1a8b6;
}

.box-images {
  height: 90px;
  width: 100%;
}

.bank-Logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pay-Now {
  color: #F1AE01 !important;
}