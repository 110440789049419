The container .radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radioCheckmark {
  position: absolute;
  top: 0;
  /* left: 0; */
  height: 25px;
  width: 25px;
  border: 1px solid #b3a9a99b;
  border-radius: 50%;
}

.radioContainer input:checked ~ .radioCheckmark {
  background-color: transparent;
  border: 1px solid #f1ae01;
}

.radioCheckmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radioContainer input:checked ~ .radioCheckmark:after {
  display: block;
}

.radioContainer .radioCheckmark:after {
  top: 5px;
  left: 5px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f1ae01;
}
